<template>
    <div class='app-container'>
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="送审单位:">
                <el-select v-model="search.institution" filterable size="small" clearable placeholder="请选择状态"
                    class="form-line-item"
                           ref="selectInstitution"
                           @visible-change="isShowSelectOptions"
                >
                    <el-option v-for="item in institutionList" :key='item.institutionId' :value="item.institutionId"
                        :label="item.institutionName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="margin-left:10px;">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                    搜索
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                    重置
                </el-button>
            </el-form-item>
        </el-form>
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="mini" icon="el-icon-plus" @click="insertReviewHandle">添加</el-button>
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table class="mytable-scrollbar reviewBookList" round align="left" ref="paperTable"
                highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                :data="reviewBookList">
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="templateName" title="模板名称" width="250" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="templateInstitution" title="送审单位" width="250" :show-overflow="'tooltip'">
                </vxe-column>
                <vxe-column field="templateDegree" title="学位类型" width="200" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="templatePattern" title="评审体系" width="250" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column title="操作" min-width="200">
                    <template #default="{ row }">
                        <el-button size="small" type="text" @click="routeTemplateCustom(row)">模板定制
                        </el-button>
                        <el-popconfirm title="确定删除吗？" @confirm='removeTemplate(row)' style="margin-left:10px;">
                            <!-- <el-button >删除</el-button> -->
                            <el-button slot="reference" style="color:red;" size="small" type="text">删除</el-button>
                        </el-popconfirm>

                    </template>
                </vxe-column>
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <vxe-modal v-model="insertReviewBookModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
            resize :transfer='true' @hide="handleModelClose" >
            <template #title>
                添加评阅模板
            </template>
            <vxe-form :data="insertReviewBook" title-align="right" title-width="100">
                <vxe-form-item field="templateName" title="模板名称" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.templateName" placeholder="请输入" size="medium"></vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item fieldname="institutionId" title="送审单位" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.institutionId" placeholder="请选择" @change="institutionChangeHandle"
                            size="medium" transfer clearable filterable>
                            <vxe-option v-for="item in institutionList" :key='item.institutionId'
                                :value="item.institutionId" :label="item.institutionName"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="degreeId" title="学位类型" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.degreeId" placeholder="请选择" size="medium" transfer >
                            <vxe-option v-for="item in degreeList" :key='item.degreeId' :value="item.degreeId"
                                :label="item.degreeName"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="reviewSystemId" title="评审体系" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.reviewSystemId" placeholder="请选择" size="medium" transfer clearable filterable>
                            <vxe-option v-for="item in reviewList" :key='item.id' :value="item.id"
                                :label="item.patternName"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button @click="cfmInsertReivewTypeHandle" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
    export default {
        name: 'reviewBookCustom',
        data() {
            return {
                loading: false,
                insertReviewBookModel: false,
                insertReviewBook: {
                    templateName: '',
                    institutionId: '',
                    reviewSystemId: '',
                    degreeId: ''
                },
                search: {
                    institution: ''
                },
                reviewBookList: [],
                //分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 20],
                    total: 100
                },
                institutionList: [],
                degreeList: [],
                reviewList: [],
            }
        },
        created() {
            window.addEventListener('keydown', this.handleKeyPress);
            this.getReviewTemplatePreviews();
            this.getAllInstitutions();
            // this.getDegree();
            // this.getAllPatterns();
        },
        methods: {
            handleKeyPress(event) {
                if (event.keyCode === 13) {
                    this.batchSearch();
                }
            },

            batchSearch() {
                this.getReviewTemplatePreviews();
            },
            resetHandle() {
                this.search = {
                    institution: ''
                }
            },
            insertReviewHandle() {
                this.insertReviewBookModel = true;
            },
            cfmInsertReivewTypeHandle() {
                let params = this.insertReviewBook;
                this.$api.reviewCustom.postAddReviewTemplate(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("添加成功");
                            this.getReviewTemplatePreviews();
                            this.insertReviewBookModel = false;
                        } else {
                            this.$message.warning("添加失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            getReviewTemplatePreviews() {
                this.loading = true;
                let params = {
                    institutionId: this.search.institution
                };
                this.$api.reviewCustom.getReviewTemplatePreviews(params)
                    .then(res => {
                        this.loading = false;
                        if (res.data.code) {
                            this.reviewBookList = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            getDegree() {
                let params = {
                    institutionId: this.insertReviewBook.institutionId
                };
                this.$api.schoolCustom.getDegree(params)
                    .then(res => {
                        this.degreeList = res.data.data;
                    }).catch(err => {
                        this.$message.warning("获取学位类型错误");
                    })
            },
            getAllInstitutions() {
                let params = {
                    pageIndex: 1,
                    pageSize: 20000
                }
                this.$api.schoolCustom.getAllInstitutions(params)
                    .then(res => {
                        this.institutionList = res.data.data;
                        // this.page.pageCount = res.data.count;
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            institutionChangeHandle({
                value,
                $event
            }) {
                this.insertReviewBook.degreeId = '';
                this.insertReviewBook.reviewSystemId = '';
                this.getDegree();
                this.getAllPatterns();
            },
            getAllPatterns() {
                this.$api.reviewCustom.getAllPatterns()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.reviewList = res.data.data;
                            // this.insertReviewBookModel = false;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            routeTemplateCustom(row) {
                this.$store.dispatch('custom/toggleInstitution', {
                    first: row.templateInstitution,
                    second: row.templateDegree
                });
                this.$router.push({
                    name: 'templateCustom',
                    params: {
                        id: row.reviewTemplateId
                    }
                })
            },
            // 删除模板
            removeTemplate(row) {
                let params = {
                    reviewTemplateId: row.reviewTemplateId
                };
                this.$api.reviewCustom.postDeleteReviewTemplate(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功！")
                            this.getReviewTemplatePreviews();
                        } else {
                            this.$message.warning("删除失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护!");
                    })
            },
            //表格刷新
            refresh() {
                this.getReviewTemplatePreviews();
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                this.page.pageIndex = currentPage;
                this.page.pageSize = pageSize;
                this.getReviewTemplatePreviews();
            },
            firstPage() {
                this.page.pageIndex = 1;
                this.getReviewTemplatePreviews();
            },
            endPage() {
                this.page.pageIndex = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
                    .pageSize == 0 ? 0 : 1);
                this.getReviewTemplatePreviews();
            },
            isShowSelectOptions(isShowSelectOptions){
                if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
            },
            handleModelClose(){
                // console.log(132)
                this.insertReviewBook={
                    templateName: '',
                    institutionId: '',
                    reviewSystemId: '',
                    degreeId: ''
                }
            },
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

<style>
    .reviewBookList .vxe-table--body-wrapper {
        height: calc(100vh - 300px);
    }
</style>